.page-not-found-container {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.page-not-found-row {
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-not-found-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.page-not-found-img {
  width: 400px;
  height: 200px;
}

.page-not-found-title {
  margin-top: 2rem;
  margin-bottom: 2rem;
  color: #201a4b;
  font-family: "DM Sans", sans-serif;
}

.page-not-found-btn {
  background-color: #28a745;
  border-color: #28a745;
  color: #fff;
}
