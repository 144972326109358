.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation2.css-1atu56z-MuiPaper-root {
  border: none;
  box-shadow: none;
}
/* .name-column{
    margin-left: 3rem; 
 } */
.name-column {
  padding-right: -2rem;
}
.v-button:disabled {
  background-color: white;
  color: #1e1e1e;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  border: 2px solid #1a7857;
}
.srnooo {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #828282;
}
.nameeee {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #1e1e1e;
}
.Mui-TableHeadCell-Content-Wrapper.MuiBox-root.css-lapokc {
  color: black;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
.custom-header {
  color: #828282;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

#showFamilyMembersButton {
  font-size: small;
  background-color: #1a7857;
  border-color: transparent;
}

#showFamilyMembersButton:hover {
  background-color: #2ac08c;
  border-color: #2ac08c;
}

#showFamilyMembersButton:disabled {
  background-color: gray;
  border-color: transparent;
  width: 100px
} 