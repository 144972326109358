.profile-container-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.profile-container {
  background-color: #fff;
  padding: 2rem;
  border: 1px solid #ffffff;
  border-radius: 14px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(255, 255, 255, 0.1);
}

.profile-header {
  text-align: center;
  margin-bottom: 2rem;
}

.profile-avatar-wrapper {
  position: relative;
  margin-bottom: 1.5rem;
}

.profile-avatar {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
  margin: auto;
}

.placeholder-avatar {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-color: rgb(141, 141, 141);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.placeholder-avatar-text {
  font-size: 3rem;
  color: rgb(255, 255, 255);
  font-weight: bold;
}

.profile-name {
  font-size: 1.8rem;
  font-weight: bold;
}

.profile-email {
  color: #6c757d;
}

.profile-details {
  margin-top: 2rem;
}

.profile-info {
  display: flex;
  justify-content: space-between;
  padding: 0.75rem 0;
}

.profile-label {
  font-weight: bold;
  color: #495057;
}

.profile-value {
  color: #212529;
}
