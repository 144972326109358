#errText {
  color: red;
  font-family: "Inter", sans-serif;
  font-size: 0.8rem;
  font-weight: 400;
  text-align: left;
  margin-top: 0.2rem;
}

#add-society-button {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #ffffff;
  border: none;
  width: 45%;
}
#close-add-society{
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #ffffff;
  border: none;
  width: 45%;
}

#societyLabel {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.36px;
  color: #000000;
}
