/* Ensure the CSS specificity is high enough to override Bootstrap styles within the Legal component */
.legal-tabs .nav-tabs .nav-link {
  font-weight: 500;
  color: #1a7857 !important;
  text-align: center;
  width: 100%;
}

.legal-tabs .nav-tabs .nav-link:hover {
  background: #1a7857 !important;
  color: #fff !important;
}

.legal-tabs .nav-tabs .nav-link:active,
.legal-tabs .nav-tabs .nav-link.active {
  background: #1a7857 !important;
  color: #fff !important;
}

/* Ensure each tab takes equal space */
.legal-tabs .nav-tabs .nav-item {
  flex: 1;
  text-align: center;
}

/* Ensure the Nav component takes full width */
.legal-tabs .nav-tabs {
  display: flex;
  width: 100%;
}

#nav {
  width: 100%;

}
@media (max-width:449px) {
  #nav {
    font-size: smaller;
  }
  
  .legal-tabs .nav-tabs .nav-link {
    width: 100px;
  }
  #nav {
    width: 50%;
  
  }
}

@media (max-width: 485px) {
  #nav {
    width: 70%;
  }
}

@media (max-width:1041px) {
  #nav {
    min-width: 400px;
  }
}

@media (min-width:1400px) {
  .legal-tabs{
    max-width: 100%;
  }
}