@keyframes popIn {
    0% {
      transform: scale(0.5);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  @keyframes popOut {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    100% {
      transform: scale(0.5);
      opacity: 0;
    }
  }
  
  .modal.fade.show {
    display: block;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .modal.fade.show .logout-modal-content {
    animation: popIn 0.3s ease-in-out;
  }
  
  .modal.fade .logout-modal-content {
    animation: popOut 0.3s ease-in-out;
  }
  
  .logout-modal-content {
    background-color: #ffffff;
    border-radius: 0.75rem;
    border: 1rem;
    border: 1px solid #e0e0e0;

  }
  
  .logout-modal-title {
    font-size: 1.2rem;
    color: #000000;
  }
  
  .logout-modal-close {
    background-color: transparent;
    border: none;
    color: #c5c5c5;
    font-size: 2.3rem;
  }
  
  .logout-modal-body {
    font-size: 1rem;
    color: #333333;
  }
  
  .logout-modal-btn-cancel {
    padding: 10px 30px;
    border: none;
  }
  
  .logout-modal-btn-logout {
    padding: 10px 30px;
    border: none;
  }
  
  .modal-content-outline{
    /* border: 1px solid #1a7857; */
    border-radius: 0.75rem;
  }

  .logout-modal-btn-logout:hover {
    background-color: #e43d3d;
  }
 