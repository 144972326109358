#notice-button123 {
  font-family: Inter;
  font-size: small;
  font-weight: 400;
  text-align: left;
  color: #fff;
  border: 1px solid #1a7857;
  padding: 0.3rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  width: 100px;
  justify-content: center;
  align-items: center;
}
#notice-icon {
  font-size: 22px;
  color: #1a7857;
}
#notice-col234 {
  margin-left: -2.4rem;
}
#recent-notice {
  margin-top: 2.8rem;
}
#recent-col {
  font-family: Inter;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  color: #828282;
  margin-left: -0.6rem;
}
#searchbar {
  border: none;
  margin-top: 0.3vh;
  box-shadow: none;
  outline: transparent;
  width: 80%;
}
#searchbar :hover {
  border: none;
}
#searchicon {
  margin-left: 1rem;
}

#search-placeholder {
  border: 1px solid #e0e0e0;
  border-radius: 10px;
}

#searchbar::placeholder {
  color: #828282 !important;
  font-size: 18px;
}
#searchbar::placeholder:hover {
  border: none !important;
}
#filter-button {
  background-color: white;
  color: #828282;
  border: 1px solid #e0e0e0;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
#filter-icon {
  font-size: 24px;
}
#icon-col {
  display: flex;
  justify-content: flex-end;
}
#listicon {
  font-size: 20px;
  margin-left: 1.2rem;
}
#icon-div {
  background-color: #f7f7f7;
  padding-right: 1rem;
  border-radius: 10px;
}
#xyz {
  font-family: Inter;
  font-size: 35px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: -0.01em;
  color: #1e1e1e;
}
#xyz-row {
  margin-top: 1rem;
}
@media (max-width: 767px) {
  #notice-col234 {
    margin-left: 6.6rem;
    margin-top: -2.3rem;
  }
}
@media (max-width: 767px) {
  #filter-button {
    margin-top: 0.5rem;
    margin-left: -0.5rem;
  }
}
@media (max-width: 767px) {
  #icon-col {
    display: flex;
    justify-content: flex-start;
    margin-top: 0.5rem;
    margin-left: -0.5rem;
  }
}

#subadminBtns {
  display: flex;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

#subroleBtn {
  margin-right: 1rem;
  border: 1.5px solid #c4c4c4;
  font-size: small;
}

#selectedSubroleBtn {
  margin-right: 1rem;
  border: 1.5px solid #1a7857;
  font-size: smaller;
  color: #1a7857;
  font-weight: 600;
}

#verifyButton:hover {
  background-color: #1a7857;
  color: white;
  border-color: #1a7857;
}
#verifyButton {
  border: 1.5px solid #1a7857;
  background-color: white;
  color: #1a7857;
  font-size: small;
  transition: 0.3s ease-in-out;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  width: 5rem;
}


.verify-btn {
  /* background-color: #1a7857; */
  border-color: transparent;
  width: 100px
}

/* .verify-btn:hover {
  background-color: #2ac08c;
  border-color: #2ac08c;
} */

.verify-btn:disabled {
  background-color: gray;
  border-color: transparent;
  width: 100px
} 