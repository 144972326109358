#deleteButton {
  border: 1.5px solid rgb(226, 12, 12);
  background-color: white;
  color: rgb(226, 12, 12);
  font-size: small;
  transition: 0.3s ease-in-out;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 3px;
}

#deleteButton:hover {
  background-color: rgb(226, 12, 12);
  color: white;
  border-color: rgb(195, 33, 33);
}
#activateButton {
  border: 1.5px solid #1a7857;
  background-color: white;
  color: #1a7857;
  font-size: small;
  transition: 0.3s ease-in-out;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 3px;
  justify-content: center;
  width: 8rem;
}

#deactivateButton:hover {
  background-color: rgb(109, 117, 124);
  color: white;
  border-color: rgb(109, 117, 124);
}
#deactivateButton {
  border: 1.5px solid rgb(109, 117, 124);
  background-color: white;
  color: rgb(109, 117, 124);
  font-size: small;
  transition: 0.3s ease-in-out;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  width: 8rem;
}

#activateButton:hover {
  background-color: #1a7857;
  color: white;
  border-color: #1a7857;
}

#deleteButtonIcon {
  width: 1.2rem;
  height: 1.2rem;
}

/* #deleteButtonIcon:hover {
  color: white;
} */

#addSocietyButtonDiv {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
}

#addSocietyButton {
  background-color: #1a7857;
  border-color: 1px solid #1a7857;
  transition: 0.3s ease-in-out;
  height: 42px;
  margin-top: 10px;
}

#addSocietyButton:hover {
  opacity: 0.8;
}

/* .add-new-society-button,
.add-new-society-button:hover,
.add-new-society-button:active,
.add-new-society-button:focus {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  font-weight: 500;
  font-family: "Roboto";
  background-color: #135c42 !important;
  color: #fff !important;
  border: 2px solid #135c42 !important;
  margin: 5px 0px;
} */
