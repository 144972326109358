#car-rrd{
    padding: 1.3rem;
    margin-bottom: 0.6rem;
    box-shadow: 0px 0px 4px 0px #0000001A;
    border-radius: 8px;

}
#John-D{
    font-family: Poppins;
font-size: 16px;
font-weight: 500;
line-height: 20px;
letter-spacing: 0.25px;
text-align: left;

}
#is-a{
    font-family: Poppins;
font-size: 16px;
font-weight: 400;
line-height: 20px;
letter-spacing: 0.25px;
text-align: left;

}
#rejected-button{
  width: 100%;
  background-color: #F14336;
  font-family: Poppins;
font-size: 13px;
font-weight: 500;
line-height: 20px;
border: none;
}
#approve-button{
    width: 100%;
    background-color: #1A7856;
    font-family: Poppins;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  border: none;
}
#my-photooo{
  height: 98%;
}
@media(max-width:767px){
  #my-photooo{
    height: 60%;
  }
  
}
@media(max-width:767px){
  #car-rrd{
    padding: 0.7rem;
}  
}
@media(max-width:767px){
  #John-D{ 
font-size: 13px;
}
}
@media(max-width:767px){
  #is-a{ 
font-size: 13px;
}
}