#visitor-image {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
}

.visitor-image-wrapper {
  position: relative;
  display: inline-block;
  width: 4rem;
  height: 4rem;
}

.visitor-image-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.vm-placeholder-avatar {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background-color: rgb(141, 141, 141);
  display: flex;
  justify-content: center;
  align-items: center;
}

.vm-placeholder-avatar-text {
  font-size: 2rem;
  color: rgb(255, 255, 255);
  font-weight: bold;
}

.v-button {
  min-width: 70px;
}
