.modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal-content {
  background-color: rgb(255, 255, 255);
  padding: 10px;
  border-radius: 20px;
  width: 100%;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #1a7857;
  color: white;
  padding: 10px;
}
.modal-title {
  margin: 5px;
}
.btn-close {
  background: none;
  border: none;
  color: rgb(255, 255, 255);
  font-size: 2.5em;
  cursor: pointer;
}

.modal-body {
  padding: 15px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input {
  width: 100%;
  padding: 8px;
  border-radius: 3px;
  border: 1px solid #ccc;
}

.invalid {
  border-color: red;
}

.error-text {
  color: red;
  font-size: 0.9em;
}

.spinner {
  text-align: center;
  margin-top: 10px;
}

.modal-footer {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
}

.btn-primary {
  background-color: #1a7857;
  color: rgb(255, 255, 255);
  padding:  7px 20px;
  border: none;
  cursor: pointer;
  border-radius: 8px;
}

.btn-primary:hover {
  background-color: #155944;
}

.btn-primary:active {
  background-color: #0e3d2c;
}

.btn-secondary {
  background-color: #929292;
  border-color: #929292;
  color: white;
}

.btn-secondary:hover {
  background-color: #b4b4b4;
  border-color: #b4b4b4;
  color: white;
}

.btn-secondary:focus, 
.btn-secondary:active, 
.btn-secondary:focus:active {
  background-color: #9b9b9b !important;
  border-color: #9b9b9b !important;
  box-shadow: none;
  color: white !important;
}


.btn-danger {
  background-color: #dc3545;
  border-color: #dc3545;
  color: white;
}

.btn-danger:hover {
  background-color: #c82333;
  border-color: #bd2130;
  color: white;
}

.btn-danger:focus, 
.btn-danger:active, 
.btn-danger:focus:active {
  background-color: #dc3545 !important;
  border-color: #dc3545 !important;
  box-shadow: none;
  color: white !important;
}
.btn-danger:disabled {
  background-color: #ff6b7a; 
  border-color: #ff6b7a;    
  color: white;
  opacity: 0.65;          
}