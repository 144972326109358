.initial-renderer-container {
  height: 100vh;
  background: #fff;
}

.initial-renderer-sidebar {
  background: #fff;
}

.initial-renderer-main {
  background: #fff;
}

.initial-renderer-content {
  max-height: calc(100vh);
  max-width:100%;
  background: #fff;
  overflow-y: scroll;
  margin-right: 0px;
}

@media only screen and (max-width: 1200px) {
  .initial-renderer-content {
    max-width: 100%;
    /* margin-right: 0px; */
  }
}

@media only screen and (max-width: 992px) {
  .initial-renderer-content {
    max-width: 93%;
    /* margin-right: 0px; */
  }
}

@media only screen and (max-width: 768px) {
  .initial-renderer-content {
    max-width: 100%;
  }
}

@media only screen and (max-width: 576px) {
  .initial-renderer-content {
    max-width: 100%;
  }
}
