#notice-button {
  background-color: white;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #000302;
  border: 1px solid #1a7857;
  padding: 0.3rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
#notice-icon {
  font-size: 22px;
  color: #1a7857;
}
#notice-col45 {
  /* margin-left: -2.4rem; */
}
#recent-notice {
  margin-top: 2.8rem;
}
#recent-col {
  font-family: Inter;
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  color: #828282;
  margin-left: -0.6rem;
}
#searchbar {
  border: none;
  margin-top: 0.3vh;
  box-shadow: none;
  outline: transparent;
  width: 80%;
}

.search-and-filter {
  margin-top: 30px;
}
#searchbar :hover {
  border: none;
}
#searchicon {
  margin-left: 1rem;
  width: 100%;
}

#search-placeholder {
  border: 1px solid #e0e0e0;
  border-radius: 10px;
}

#searchbar::placeholder {
  color: #828282 !important;
  font-size: 18px;
  width: 100%;
}
#searchbar::placeholder:hover {
  border: none !important;
}
#filter-button2345 {
  background-color: white;
  color: #828282;
  border: 1px solid #e0e0e0;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 500;
}
#filter-icon {
  font-size: 24px;
}
#icon-col321 {
  display: flex;
  justify-content: flex-end;
}
#listicon {
  font-size: 20px;
  margin-left: 1.2rem;
}
#icon-div {
  background-color: #f7f7f7;
  padding-right: 1rem;
  border-radius: 10px;
}
#xyz {
  font-family: Inter;
  font-size: 35px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: -0.01em;
  color: #1e1e1e;
}
#xyz-row {
  margin-top: 1rem;
}
@media (max-width: 767px) {
  #filter-button2345 {
    margin-top: 0.5rem;
    margin-left: -0.5rem;
  }
}
@media (max-width: 767px) {
  #notice-col45 {
    margin-left: 0;
    margin-top: 0.5rem;
  }
}
@media (max-width: 767px) {
  #icon-col321 {
    display: flex;
    justify-content: flex-start;
    margin-top: 0.5rem;
    margin-left: -0.5rem;
  }
}

#notification-dropdown {
  /* width: 10rem; */
}
.dropdown-item:hover {
  background: #1a7857;
  color: white;
}

.active > .page-link,
.page-link.active {
  z-index: 3;
  color: #fff;
  background-color: #1a7857 !important;
  border-color: #1a7857 !important;
}
.page-item {
  color: #1a7857 !important;
}

.page-link {
  color: #1a5857;
}
