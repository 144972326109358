/* Set body and html to have no margins and height to 100% */
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.login-container {
  position: relative;
  background-image: url("../../assets/dashboard/bgwhiteimage.png");
  background-size: cover;
  background-position: center;
  height: 100vh;
  width: 100vw;
  overflow: hidden; /* Prevent scrollbars */
}

.login-content {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
  overflow: hidden;
}

.login-box {
  padding: 2rem;
  box-shadow: 0px 0px 4px 0px #0000001a;
  border-radius: 13px;
  background-color: white;
  max-width: 30rem;
  width: 100%; /* Ensure width is responsive */
  max-height: 90vh; /* Limit height to avoid overflow */
}

.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-img {
  width: 100px;
  margin: 10px;
}

.admin-account {
  font-family: "Inter", sans-serif;
  font-size: 21px;
  font-weight: 660;
  text-align: center;
  color: #000;
  margin-bottom: 10px;
}

.enter-email {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  text-align: center;
  color: #000;
  margin-top: 0.3rem;
}

.required-icon {
  color: red;
}
.sign-in-button {
  background-color: #006744;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  margin: auto;
  width: 95%;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.sign-in-button:hover {
  background-color: #004f33;
}

.sign-in-button:active {
  background-color: #003e28;
}

.sign-in-button:focus {
  outline: 2px solid #004f33;
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #006744;
  --bs-btn-border-color: #006744;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #004f33;
  --bs-btn-hover-border-color: #004f33;
  --bs-btn-focus-shadow-rgb: 0, 103, 68;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #003e28;
  --bs-btn-active-border-color: #003e28;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #006744;
  --bs-btn-disabled-border-color: #006744;
}


.error-text {
  color: red;
  font-family: "Inter", sans-serif;
  font-size: 0.8rem;
  margin-top: 0.2rem;
}

.toggle-btn {
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #000;
  background-color: #fff;
  border: 1px solid #006744;
  border-radius: 0.375rem;
  padding: 0.5rem 1.5rem;
  margin: 0 0.5rem;
  cursor: pointer;
  transition: background-color 0.2s ease, border-color 0.2s ease;
}

.toggle-btn:hover {
  background-color: #008f57;
  color: #fff;
}

.toggle-btn.selected {
  color: #fff;
  background-color: #006744;
  border: 2px solid #006744;
}

.password-container {
  position: relative;
}

.password-toggle {
  position: absolute;
  top: 6px;
  right: 18px;
  cursor: pointer;
}

@media (max-width: 496px) {
  .toggle-btn {
    font-size: 14px;
    padding: 0.4rem 1rem;
    margin: 0 0.25rem;
  }

  .login-box {
    padding: 1.5rem;
    margin-top: -5rem;
    max-width: 90%;
  }

  .enter-email {
    font-size: 15px;
  }
}
