.visitor-roww {
  padding: 1rem;
}

.dash-table-container {
  border: 1px solid #cfcfcf;
  box-shadow: 0px 4px 12px 0px #0000000a;
  border-radius: 15px;
  margin-top: 1.5rem;
  padding: 1.5rem;
  background-color: white;
  margin-left: 20px;
  min-height: 500px;
}

.loader-container {
  max-height: 400px;
  align-items: center;
  justify-content: center;
}
.maintenance-loader {
  min-height: 400px;
  align-self: center;
  top: 0;
}

.maintainance-row {
  background-color: #bbbbbb49;
  margin-top: 5px;
  color: #fff;
  border-width: 1px;
  padding: 2px;
  border-radius: 3px;
}

.barchart-container,
.table-container {
  border: 1px solid #cfcfcf;
  box-shadow: 0px 4px 12px 0px #0000000a;
  border-radius: 15px;
  margin-top: 1.5rem;
  padding: 1.5rem;
  background-color: white;
}

.barchart-container {
  background-color: white;
}

.table-container {
  background-color: white;
}

.visitor-coll {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #000000;
}

.visitor-coll2 {
  display: flex;
  justify-content: flex-end;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #000000;
  cursor: pointer;
}

.filterrrr {
  font-size: 23px;
  font-weight: 900;
  color: #000000;
}
#barchat-row2 {
  position: relative;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 4px 12px 0px #0000000a;
  width: 49%;
  margin-left: 1rem;
  padding: 1.7rem;
  border-radius: 10px;
  min-height: 70vh;
  max-height: 70vh;

  /* margin-top: 2rem; */
}
.maintenance {
  font-family: Inter;
  font-size: 17.5px;
  font-weight: 600;
  /* line-height: 24px; */
  text-align: left;
  margin-bottom: 1rem;
}

.Nameeee {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  /* line-height: 24px; */
  text-align: left;
  color: #828282;
}

.Nameeee123 {
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #000000;
}

.table-header {
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

/* Remove margin and padding on container to avoid empty space */
.container-fluid {
  margin: 0;
  padding: 0;
}

#pie-chart-col {
  position: relative;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 4px 12px 0px #0000000a;
  width: 49%;
  border-radius: 10px;
  padding: 3rem 0rem 2rem 0rem;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 70vh;
  max-height: 70vh;
  /* margin: 2rem 0rem; */
}
#pie-chart-col-last {
  position: relative;
  border: 1px solid #e0e0e0;
  box-shadow: 0px 4px 12px 0px #0000000a;
  width: 49%;
  border-radius: 10px;
  padding: 3rem 0rem 2rem 0rem;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 70vh;
  max-height: 70vh;
  margin-left: 1rem;
}
#full-bar-chart {
  border: 1px solid #e0e0e0;
  box-shadow: 0px 4px 12px 0px #0000000a;
  border-radius: 10px;
  padding: 2rem 5rem 5rem 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 70vh;
  /* max-height: 70vh; */
  margin: 1rem 0rem 2rem 0rem;
}

/* Media Queries for Responsiveness */
@media (max-width: 1378px) {
  .barchart-container,
  .table-container {
    width: 100%;
    margin-left: 0;
  }
  .dash-table-container {
    width: 100%;
    margin-left: 0px;
  }
}

@media (min-width: 1379px) {
  .barchart-container {
    width: 47%;
  }
  .table-container {
    width: 100%;
  }

  .table-container {
    margin-left: 1rem;
  }
}

@media (max-width: 991px) {
  .barchart-container,
  .table-container {
    width: 100%;
    margin-left: 20px;
  }
  .dash-table-container {
    width: 100%;
    margin-left: 20px;
  }

  #pie-chart-col {
    width: 100%;
    margin-bottom: 2rem;
    /* margin: 2rem 0rem; */
  }
  #pie-chart-col-last {
    width: 100%;
    margin-bottom: 2rem;
    margin-left: 0px;
  }

  #barchat-row2 {
    width: 100%;
    margin-left: 0rem;
    padding: 1.7rem;
    border-radius: 10px;
    min-height: 40vh;
    /* max-height: 40vh; */
  
    /* margin-top: 2rem; */
  }
}
@media (max-width: 767px) {
  .dash-table-container,
  .barchart-container,
  .table-container {
    width: 100%;
    margin-left: 10px;
  }
}

@media (max-width: 767px) {
  #pie-chart-col,
  #pie-chart-col-last {
    width: 100%;
    padding: 1rem;
    /* min-height: 40vh; */
    margin: 1rem 0rem;
  }
  #full-bar-chart {
    padding: 1rem;
    margin: 1rem 0rem;
    min-height: 40vh;
  }
}

.dash-sub-heading-1 {
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 500;
  color: #1e1e1e;
}
#dash-sub-heading-1-container {
  display: flex;
  align-items: center;
  gap: 5px;
}

#stats-dropdown {
  color: #fff;
  background-color: #1a7857;
  border: #1a7857;
  width: 10rem;
}

.graph-heading {
  position: absolute;
  top: 2px;
  left: 10px;
  font-size: 16px;
  font-weight: 500;
  font-family: "Roboto";
  color: gray;
}
