.add-maintenance-row {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
}

.add-maintenance-col {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

#add-maintenance-btn {
  background-color: white;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #000302;
  border: 1px solid #1a7857;
  padding: 0.3rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
#add-maintenance-icon {
    font-size: 22px;
    color: #1a7857;
  }

#add-maintenance-btn:hover {
    background-color: #1a7857;
    color: white;
    #add-maintenance-icon {
        color: #ffffff;
      }
}

.maint-btn {
  width: 100px;
}