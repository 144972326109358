/* Styling for table text */
.empiiid {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #1e1e1e;
}

.empiiid2 {
  font-family: Inter;
  font-size: 19px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: #1e1e1e;
  cursor: pointer;
}

/* Styling for buttons inside table */
.apartment-button {
  background-color: white;
  color: #1e1e1e;
  border: 1px solid #1a7857;
  padding: 0.3rem;
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
  cursor: pointer;
}

.circle-photo {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  object-fit: cover;
}

/* Table container styling */
.table-container {
  width: 100%;
  overflow-x: auto; /* Ensure table is scrollable on smaller screens */
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .table-container {
    width: 95%; /* Adjust width for medium screens */
    margin: 0 auto;
  }
}

@media (max-width: 992px) {
  .empiiid,
  .empiiid2 {
    font-size: 14px;
    line-height: 20px;
  }

  .apartment-button {
    font-size: 12px;
    padding: 0.2rem;
  }

  .circle-photo {
    width: 25px;
    height: 25px;
  }

  .table-container {
    width: 100%;
    padding: 0.5rem;
  }
}

@media (max-width: 768px) {
  .table-container {
    width: 100%;
    padding: 0.5rem;
  }

  .empiiid,
  .empiiid2 {
    font-size: 12px;
    line-height: 18px;
  }

  .apartment-button {
    font-size: 11px;
    padding: 0.1rem 0.2rem;
  }

  .circle-photo {
    width: 20px;
    height: 20px;
  }
}

/* Ensure the table is responsive and adjusts properly on smaller devices */
.table-container table {
  width: 100%; /* Ensure table takes full width of the container */
  table-layout: auto;
  border-spacing: 0;
}

/* Add some extra styling to ensure the table rows are spaced well */
.table-container table td,
.table-container table th {
  padding: 0.5rem;
  text-align: left;
  vertical-align: middle;
}

/* Fix for larger screens above 1379px */
@media (min-width: 1379px) {
  .table-container {
    width: 100%; /* Ensure full width for larger screens */
    margin: 0 auto;
  }
}
