/* #add-notice-form-container{
  width: 50%;
} */

#cross-icon-id {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0.5rem;
}


.cross-row {
  padding: 2rem;
}
.hr-line {
  margin-top: 0px;
}
.write-notice {
  font-family: "Plus Jakarta Sans";
  font-size: 20px;
  font-weight: 600;
  color: #1b2b41;
}
#crossss {
  font-size: 22px;
  font-weight: 600;
  cursor: pointer;
}
#title-label {
  color: #424242;
  font-family: "Inter";
  font-size: 15px;
  font-weight: 500;
}
#null-placeholder {
  box-shadow: none;
  border-radius: 3px;
  border: 2px solid #006944;
}
#null-placeholder1 {
  box-shadow: none;
  padding: 1rem;
  border-radius: 3px;
  border: 2px solid #006944;
}
.check-box {
  color: #424242;
  font-family: "Inter";
  font-size: 15px;
  font-weight: 500;
  margin-top: 0.8rem;
}
.check-box:first-child {
  margin-top: 0rem;
}
.check-box-small {
  color: #424242;
  font-family: "Inter";
  font-size: 12px;
  font-weight: 500;
}
#choose-specific {
  font-family: "Plus Jakarta Sans";
  font-size: 12px;
  font-weight: 500;
  /* text-align: left; */
  margin-bottom: 0 !important;
  color: #1b2b41b8;
}
#select-id {
  box-shadow: none;
}
#close-button {
  width: 60%;
  font-family: "Roboto";
  font-size: 17px;
  font-weight: 500;
  color: #000302;
  border: 1.5px solid #006944;
  background-color: white;
  border-radius: 20px;
}
#close-button1 {
  width: 60%;
  font-family: "Roboto";
  font-size: 17px;
  font-weight: 500;
  color: white;
  border: 1.5px solid #006944;
  background-color: #006944;
  border-radius: 20px;
}
.chip {
  align-items: center;
  background: #006944 !important;
  border-radius: 11px;
  color: #fff;
  display: inline-flex;
  font-size: 12px !important;
  font-weight: 500 !important;
  font-family: "Plus Jakarta Sans" !important;
  line-height: 19px;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 4px 10px;
}
.cross-row1 {
  margin-top: 4rem;
}

@media (max-width: 767px) {
  .cross-row1 {
    margin-top: 2rem;
  }
  #close-button,
  #close-button1 {
    width: 100%;
    margin-bottom: 10px; /* Adds space between the buttons vertically */
  }
}
@media (max-width: 992px) {
  .cross-row1 {
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
  }

  #close-button,
  #close-button1 {
    width: 100%;
    margin-bottom: 10px; /* Adds space between the buttons vertically */
  }
}
.multiSelectContainer input {
  margin-top: 0 !important;
  font-family: "Plus Jakarta Sans" !important;
  font-size: 12px !important;
  line-height: 0 !important;
}
/* Custom checkbox styling */
.custom-checkbox {
  display: flex;
  align-items: center;
  gap: 5px; /* Adjust gap as needed */
}
.form-check {
  /* display: block; */
  /* min-height: 1.5rem; */
  padding-left: 0 !important;
  /* margin-bottom: .125rem; */
}

.custom-checkbox .form-check-input {
  width: 1rem;
  height: 1rem;
  border: 2px solid #006944;
  margin: 0;
  vertical-align: middle;
}

.custom-checkbox .form-check-input:checked {
  background-color: #006944;
  border-color: #006944;
}

.custom-checkbox .form-check-input:focus {
  box-shadow: none;
}

.custom-checkbox .form-check-label {
  margin-bottom: 0;
  line-height: 1rem;
  vertical-align: middle;
}

/* custom list hover */
.multiSelectContainer li:hover {
  padding: 10px;
  background: #006944 !important;
  /* font-family: "Plus Jakarta Sans" !important;
  font-size: 12px !important; */
}
.highlightOption {
  background: #006944 !important;
  color: #fff;
  /* font-family: "Plus Jakarta Sans" !important;
  font-size: 12px !important; */
}

.ql-snow * {
  max-height: 230px !important;
}




#button-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

#button-wrapper {
  flex: 1;
  display: flex;
  justify-content: center;
}

#action-button {
  width: 100%;
  padding: 10px;
  border: none;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
}

#action-button:hover {
  background-color: #0056b3;
}

#close-btn {
  background-color: #6c757d;
}

#close-btn:hover {
  background-color: #5a6268;
}

#loading-spinner {
  display: flex;
  justify-content: center;
}

@media (max-width: 992px) {
  #button-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  #action-button {
    width: 100%;
    margin-bottom: 10px;
  }

  #button-wrapper {
    width: 100%;
  }
}
