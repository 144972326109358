#searchbar {
    border: none; 
    margin-top: 0.3vh;
    box-shadow: none;
    outline: transparent;
}
#searchbar :hover{
   border: none;
}
#searchicon {
    margin-left: 1rem; 
}

#search-placeholder {
    border: 1px solid #E0E0E0;
    border-radius: 10px;
}


#searchbar::placeholder {
    color: #828282 !important; 
    font-size: 18px;
}
#searchbar::placeholder:hover {
    border: none !important;
}
#filter-button-filter{
    background-color: white;
    color: #828282;
    border: 1px solid #E0E0E0;
font-family: Inter;
font-size: 16px;
font-weight: 400;
line-height: 24px;

}
#filter-icon{
    font-size: 24px;
}

#icon-col-icon{
    display: flex;
    justify-content: flex-end;
}
#listicon{
    font-size: 20px;
    margin-left: 1.2rem;
}
#icon-div{
    background-color: #F7F7F7;
    padding-right: 1rem;
    border-radius: 10px;
}
#xyz{
    font-family: Inter;
font-size: 35px;
font-weight: 500;
line-height: 30px;
letter-spacing: -0.01em;
text-align: left;

color: 
#1E1E1E;
}
#xyz-row{
    margin-top: 1rem;
}
@media(max-width:767px){
    #filter-button-filter{
       margin-top: 0.5rem;
       margin-left: -0.5rem;
    
    }
}
@media(max-width:767px){
    #icon-col-icon{
       margin-top: -2.3rem;
    }
}