.action-button {
  font-size: small;
  transition: 0.3s ease-in-out;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: 12rem;
  background-color: white;
  border: 1.5px solid;
  border-color: gray;
}


.action-button:hover {
  color: white !important;
}

#button-Icon {
  width: 1.2rem;
  height: 1.2rem;
}
