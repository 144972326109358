#card-row-row {
  padding: 1.5rem 1.8rem;
  border: 1px solid #cfcfcf;
  box-shadow: 0px 0px 4px 0px #0000001a;
  margin-bottom: 1.2rem;
  border-radius: 14px;
  min-height: 350px;
  max-height: 350px;
  overflow-y: auto;
}
#card-row-row-1 {
  padding: 1.5rem 1.8rem;
  border: 1px solid #fafafa;
  box-shadow: 0px 0px 4px 0px #0000001a;
  margin-bottom: 1.2rem;
  border-radius: 14px;
  min-height: 200px;
  max-height: 200px;
  overflow-y: auto;
}
.complaint-row {
  min-height: 250px !important;
}

.appartment-name234 {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #1e1e1e;
}

.z1-button:disabled {
  padding: 0.2rem;
  font-family: Inter;
  font-size: 15px;
  font-weight: 600;
  color: #1e1e1e;
  background-color: white;
  border: 2px solid #1a7857;
  border-radius: 5px;
}

#dear {
  font-family: "Plus Jakarta Sans";
  font-size: 15px;
  font-weight: 400;
  text-align: left;
  color: #1e1e1e;
}

#close-button234 {
  font-family: "Roboto";
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  position: absolute;
  bottom: 15px;
  right: 20px;
}

#notice-delete-spinner {
  font-size: 10px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 15px;
  right: 20px;
}

.vs-button-closed:disabled {
  background-color: hsl(0, 0%, 59%);
  color: #fff;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  border: 2px solid #979797;
  opacity: 100%;
  min-width: 100px;
}

.vs-button-open:disabled {
  background-color: #ffffff;
  color: #1a7857;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  border: 2px solid #1a7857;
  opacity: 100%;
  min-width: 100px;
}

/* Responsive Styles */
@media (max-width: 767px) {
  .appartment-name234 {
    font-size: 14px;
  }

  #card-row-row {
    padding: 1rem 1.2rem;
  }

  .appartment-name134 {
    flex-direction: column;
    align-items: flex-start;
  }

  .all-button-all {
    margin-top: 0.5rem;
    margin-left: 0;
    flex-direction: column;
    align-items: flex-start;
  }

  .date-col-col {
    justify-content: flex-start;
    margin-top: 0.5rem;
    flex-direction: column;
    align-items: flex-start;
  }

  .apartment-small {
    justify-content: space-between;
    margin-bottom: 1rem;
  }
}

/* Specific Change: Move the button column below in smaller screens */
@media (max-width: 1100px) {
  .button-col {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }
}

@media (max-width: 991px) {
  #card-row-row {
    margin-left: 1.1rem;
  }
}

@media (min-width: 1379px) {
  #card-row-row {
    width: 99%;
  }
}
