.visitor-roww {
  padding: 1rem;
}

.dash-table-container {
  border: 1px solid #cfcfcf;
  box-shadow: 0px 4px 12px 0px #0000000a;
  border-radius: 15px;
  margin-top: 1.5rem;
  padding: 1.5rem;
  background-color: white;
  min-height: 500px;
}

.loader-container {
  max-height: 400px;
  align-items: center;
  justify-content: center;
}

.maintenance-loader {
  min-height: 400px;
  align-self: center;
  top: 0;
}

.maintainance-row {
  background-color: #bbbbbb49;
  margin-top: 5px;
  color: #fff;
  border-width: 1px;
  padding: 2px;
  border-radius: 3px;
}

.barchart-container,
.table-container {
  border: 1px solid #cfcfcf;
  box-shadow: 0px 4px 12px 0px #0000000a;
  border-radius: 15px;
  margin-top: 1.5rem;
  padding: 1.5rem;
  background-color: white;
}

.visitor-coll {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #000000;
}

.visitor-coll2 {
  display: flex;
  justify-content: flex-end;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #000000;
  cursor: pointer;
}

.filterrrr {
  font-size: 23px;
  font-weight: 900;
  color: #000000;
}

.maintenance {
  font-family: Inter;
  font-size: 17.5px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  margin-bottom: 1rem;
}

.Nameeee {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #828282;
}

.Nameeee123 {
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #000000;
}

.table-header {
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.container-fluid {
  margin: 0;
  padding: 0;
}

/* Media Queries for Responsiveness */
@media (max-width: 1378px) {
  .barchart-container,
  .table-container {
    width: 100%;
    margin-left: 0;
  }
  .dash-table-container {
    width: 100%;
    margin-left: 0px;
  }
}

@media (min-width: 1379px) {
  .barchart-container {
    width: 47%;
  }
  .table-container {
    width: 100%;
  }

  .table-container {
    margin-left: 1rem;
  }
}

@media (max-width: 991px) {
  .barchart-container,
  .table-container {
    width: 100%;
    margin-left: 20px;
  }
  .dash-table-container {
    width: 100%;
    margin-left: 20px;
  }
}

@media (max-width: 767px) {
  .dash-table-container,
  .barchart-container,
  .table-container {
    width: 100%;
    margin-left: 10px;
  }
}
