.export-button,
.export-button:hover,
.export-button:active,
.export-button:focus {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  font-weight: 500;
  font-family: "Roboto";
  color: #158a57 !important;
  background-color: #fff !important;
  border: 2px solid #158a57 !important;
  margin: 10px 0px;
}

@media (max-width: 767px) {
  .export-button {
    margin: 1rem 0rem;
  }
}
