.empiiid {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #1e1e1e;
}
.empiiid2 {
  font-family: Inter;
  font-size: 19px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: #1e1e1e;
  cursor: pointer;
}
.apartment-button {
  background-color: white;
  color: #1e1e1e;
  border: 1px solid #1a7857;
  padding: 0.3rem;
  font-family: Inter;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  text-align: left;
}
.circle-photo {
  border-radius: 50%;
  width: 30px; /* Adjust the size as needed */
  height: 30px; /* Adjust the size as needed */
  object-fit: cover;
}

.verify-btn {
  background-color: #1a7857;
  border-color: transparent;
  width: 100px;
}

.verify-btn:hover {
  background-color: #135a41;
  border-color: #135a41;
}

.verify-btn:disabled {
  background-color: gray;
  border-color: transparent;
  width: 100px;
}
