.recent-complaints{
    font-family: Inter;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    text-align: left;
    color: 
    #828282;    
}
.recent-row{
    margin-top: 1rem;
}