#card-col{
    display: flex;
    justify-content: space-between;

}
#card-row{
    padding: 1.5rem;
    padding-left: 1.8rem;
    padding-right: 1.8rem;
    border: 1px solid #FAFAFA;
    /* box-shadow: 0px 4px 4px 0px #0000001A; 
    box-shadow: 4px 0px 4px 0px #0000001A; 
    box-shadow: -4px 0px 4px 0px #0000001A; 
    box-shadow: 0px -4px 4px 0px #0000001A;  */
    box-shadow: 0px 0px 4px 0px #00000054;

      border-radius: 14px;
      margin-bottom: 1.2rem;

}
.appartment-name{
    font-family: Inter;
font-size: 16px;
font-weight: 500;
line-height: 24px;
color: #1E1E1E;

}
.z1-button{
    padding: 0.3rem;
    font-family: Inter;
font-size: 12px;
font-weight: 600;
line-height: 16px;
color: #1E1E1E;
background-color: white;
border: 1px solid #1A7857;


}
.all-button{
    padding: 0.3rem;
    padding-left: 0.4rem;
    padding-right: 0.4rem;
    font-family: Inter;
font-size: 12px;
font-weight: 600;
line-height: 16px;
color: #1E1E1E;
background-color: white;
border: 1px solid #1A7857;


}
.appartment-name1{
    font-family: Inter;
font-size: 16px;
font-weight: 500;
line-height: 24px;
color: #1E1E1E;
justify-content: flex-end;
display: flex;
}
.date-col{
    display: flex;
    justify-content: flex-end;
    font-family: Inter;
font-size: 16px;
font-weight: 400;
line-height: 24px;
color: #1E1E1E;

}
.date{
    font-family: Inter;
font-size: 16px;
font-weight: 500;
line-height: 24px;
color: #1E1E1E;

}
#dear{
    font-family: Plus Jakarta Sans;
font-size: 16px;
font-weight: 450;
line-height: 20.16px;
text-align: left;
color: #1E1E1E;

}
@media(max-width:767px){
    #card-row{
        padding: 1rem;
        padding-left: 1.2rem;
        padding-right: 1.2rem;
    
    }
    
}
@media(max-width:767px){
    .appartment-name1{
    justify-content: flex-start;
    display: flex;
    margin-top: 0.2rem;
    }
    
}
@media(max-width:767px){
    .all-button{
       margin-top: 0.2rem;
    }    
}
@media(max-width:767px){
    .date-col{
        display: flex;
        justify-content: flex-start;
      
    
    }  
}