.sbm1 {
  height: 4rem;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.sbm2 {
  height: 4rem;
  cursor: pointer;
}

.sbm3 {
  background: white;
}

.sbm4 {
  text-decoration: none;
  background: #fff;
  border-radius: 0.5rem;
  color: #000302;
  font-size: 0.92rem;
  font-weight: 500;
  margin-bottom: 1rem;
  padding: 0.6rem 0 0.3rem 0;
}

.sbm5 {
  color: #000000;
  padding: 0.25rem;
  border-radius: 0.15rem;
  margin-right: 0.5rem;
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 500;
  font-family: Inter;
  transition: 0.7s ease-in-out;
}

.sbm6 {
  text-decoration: none;
  background: #1a7857;
  border-radius: 0.5rem;
  color: #ffffff;
  font-size: 0.92rem;
  font-weight: 500;
  margin-bottom: 1rem;
  padding: 0.6rem 0 0.3rem 0;
  transition: 0.4s ease-in-out;
  .sbm5 {
    color: #fff;
  }
}

.sbm9 {
  display: flex;
  align-items: center;
  margin-top: -0.7vh;
}

.sbm11 {
  height: 1em;
  width: 1em;
}

.sbm13 {
  height: 100vh;
}

.sbm14 {
  height: calc(100vh - 6rem); /* Adjust based on header height */
  overflow-y: auto;
  padding-left: 1rem;
  margin-top: 0.5vh;
}

/* Hide scrollbar */
.sbm14::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.sbm14 {
  -ms-overflow-style: none; /* Internet Explorer */
  scrollbar-width: none; /* Firefox */
}

.sbm15 {
  padding: 0;
  padding-right: 0.5rem;
}

.sbm17 {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.sbm18 {
  height: 6rem;
  width: 8rem;
  cursor: pointer;
}

#sidebar-nav {
  height: 100vh;
  border-right: 1px solid #dbdbdb;
  position: fixed;
  width: 16.5%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0.9rem;
  background-color: #fff;
}

@media (max-width: 768px) {
  #sidebar-nav {
    width: 9.5%;
  }
}

.logout-row {
  text-decoration: none;
  background: #fff;
  border-radius: 0.5rem;
  color: #000302;
  font-size: 0.92rem;
  font-weight: 500;
  margin-bottom: 1rem;
  padding: 0.4rem 0 0.3rem 0;
}

.logout-row:hover {
  background-color: #ff4f4f;
  color: #fff;
  .sbm11 {
    color: #fff;
  }
}

/* Hide scrollbar */
.sbm4:hover {
  background-color: #1a785750;
}

.sb-profile-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.sb-profile-avatar {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  object-fit: cover;
}
.sb-profile-avatar-wrapper {
  position: relative;
  margin-bottom: 1.5rem;
}

.sb-user-data {
  margin-left: 0.8rem;
}

.sb-placeholder-avatar {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: rgb(141, 141, 141);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10%;
}

.sb-placeholder-avatar-text {
  font-size: 3rem;
  color: rgb(255, 255, 255);
  font-weight: bold;
}

.sb-profile-name {
  font-size: 1.3rem;
  font-weight: bold;
}

.sb-profile-apartment {
  color: #6c757d;
}

@media (max-width: 756px) {
  .navbar-toggler {
    margin-left: 10px;
  }
}

/* Responsive */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  #sidebar-nav {
    width: 23%;
    overflow-x: hidden;
    padding: 1rem;
  }

  .logout-row:hover {
    background-color: #ff4f4f;
    color: #fff;
  }

  .sbm4:hover {
    background-color: #1a785750;
  }
}

@media (max-width: 1484px) {
  .sbm14 {
    height: calc(100vh - 12rem); /* Adjust based on header height */
    overflow-y: auto;
    padding-left: 1rem;
    margin-top: 0.5vh;
  }
  .sb-profile-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
