#legal-delete-button {
  font-family: "Roboto";
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 25%;
}

#legal-edit-button {
  font-family: "Roboto";
  font-size: 15px;
  font-weight: 500;
  border: none;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: 25%;
}

#legal-card-row-row {
  border: 1px solid #cfcfcf;
  border-radius: 14px;
  padding: 1.5rem 1.8rem;
  box-shadow: 0px 0px 4px 0px #0000004d;
  margin-bottom: 1.2rem;
  background-color: white;
}

/* Show more button styles */
.show-more-btn,
.show-more-btn:active,
.show-more-btn:hover {
  font-weight: 600;
  color: #1a7857;
}

/* Responsive styles for LegalCard */
@media (max-width: 991px) {
  #legal-delete-button,
  #legal-edit-button {
    width: 40%;
  }

  #legal-card-row-row {
    padding: 1rem;
  }
}

@media (max-width: 767px) {
  #legal-delete-button,
  #legal-edit-button {
    width: 100%; /* Full width for small screens */
    font-size: 14px;
  }

  #legal-card-row-row {
    padding: 0.8rem;
    box-shadow: 0px 0px 2px 0px #0000004d;
  }

  .fs-5 {
    font-size: 1.2rem;
  }
}

@media (max-width: 575px) {
  #legal-delete-button,
  #legal-edit-button {
    width: 100%;
    font-size: 13px;
  }

  #legal-card-row-row {
    padding: 0.6rem;
  }

  .fs-5 {
    font-size: 1rem;
  }
}

/* Flexbox adjustments for buttons to ensure proper alignment */
.d-flex.gap-3.justify-content-end {
  flex-wrap: wrap;
  gap: 10px;
}

@media (max-width: 575px) {
  .d-flex.gap-3.justify-content-end {
    justify-content: center;
  }
}
